@import 'https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap';

:root {
  color-scheme: dark;
}

body {
  width: 100%;
  height: auto;

  > #goods-manager {
    width: 100%;
    height: auto;
    min-height: 100vh;
  }
}

a {
  text-decoration: none !important;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: Vazirmatn, sans-serif;
}

.rs-modal-title {
  margin: 2px 6px 0 0 !important;
}

.rs-modal-footer {
  > button:not(:last-child) {
    margin-left: 10px;
  }
}

.rs-input-number {
  overflow: hidden;
}

.rs-picker-select-menu-item {
  text-align: right;
  direction: rtl;
}

.rs-drawer-body {
  padding: 4px !important;
}

.rs-drawer-header {
  padding: 0 10px 0 30px !important;

  .rs-drawer-header-close {
    left: 6px !important;
    top: 4px !important;
  }
}

.rs-uploader {
  display: flex;
  align-items: center;
  margin: 20px 0;

  > .rs-uploader-trigger {
    width: 100px;
    display: flex;
    align-items: center;
  }

  > .rs-uploader-file-items {
    display: flex;
    align-items: center;

    > .rs-uploader-file-item {
      margin: 0;
    }
  }
}

.rs-nav-item:not(.rs-nav-item-active) {
  > a {
    color: #ffffff57;
  }
}
