.list-loader {
  display: flex;
  justify-content: center;
  padding-top: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #0f131af1;

  .rs-loader {
    display: inline-flex;

    > .rs-loader-spin {
      margin-left: 10px;
    }
  }
}
