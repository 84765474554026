.navbar {
  > .rs-nav {
    > .rs-nav-item {
      padding: 0;

      > a {
        padding: 12px;
        display: block;
        text-align: center;
        font-size: larger;
      }
    }
  }
}

.rs-nav-tabs .rs-nav-item.rs-nav-item-active {
  background-color: var(--rs-body) !important;
}

.rs-nav-tabs .rs-nav-item:hover,
.rs-nav-tabs .rs-nav-item:focus {
  background-color: #6a6f761a !important;
}
