.preview-image {
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 6px;
    box-shadow: var(--rs-state-focus-shadow);
  }
}

.toggle-state-loader {
  margin-top: 10px;
}
