.edit-product-modal {
  .rs-modal-body {
    padding: 20px 0;
    margin-bottom: 20px;

    > .rs-stack {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
