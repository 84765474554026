.header {
  width: 100%;
  height: 40px;
  margin: 5px 0 10px;
  border-radius: 6px;
  border: 1px solid #3c3f4378;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
