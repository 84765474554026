.auth-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  > .form-container {
    border: 2px dashed var(--rs-divider-border);
    width: 400px;
    max-width: 96%;
    height: auto;
    border-radius: 8px;
    padding: 20px;

    > form {
      text-align: left;

      > input {
        margin-bottom: 20px;
        direction: ltr;

        &::placeholder {
          direction: rtl;
          text-align: right;
        }
      }
    }
  }
}
